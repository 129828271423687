<template>
  <b-row class="match-height">
    <b-col md="12">
      <b-card-code>
        <b-form>
          <b-row>
            <!-- Field: Username -->
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px">
                  <label label-for="nickname" style="padding-top: 12px"
                    >群发任务名称：</label
                  >
                </div>
                <div style="float: left">
                  <b-form-input
                    id="title"
                    style="width: 530px"
                    v-model="title"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Full Name -->
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px">
                  <label label-for="nickname" style="padding-top: 12px"
                    >选择执行账号：</label
                  >
                </div>
                <div style="float: left">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    v-b-modal.modal-login
                  >
                    选择微信
                  </b-button>
                  <!-- <div
                    style="float: right; padding-top: 5px"
                    v-for="name in selectname"
                    :key="name"
                  > -->
                  &nbsp;<b-badge pill variant="primary" class="badge-glow">{{
                    selectname
                  }}</b-badge>
                  <!-- </div> -->
                  <b-modal
                    id="modal-login"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="选择执行帐号"
                    @ok="ok"
                  >
                    <b-form>
                      <b-form-group>
                        <div style="float: left">
                          <b-form-radio
                            v-model="selectwx"
                            name="selectwx"
                            value="1"
                            @input="clear()"
                          >
                            按归属部门筛选
                          </b-form-radio>
                        </div>
                        <div style="float: left; width: 450px">
                          <v-select
                            v-model="selectbm"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="text"
                            v-if="selectwx == '1' ? true : false"
                            :options="bmoptions"
                          />
                        </div>
                      </b-form-group>

                      <div style="float: left">
                        <b-form-group>
                          <b-form-radio
                            v-model="selectwx"
                            name="selectwx"
                            value="2"
                            @input="clear()"
                          >
                            按归属员工筛选
                          </b-form-radio>
                          <div style="float: left; width: 450px">
                            <v-select
                              v-model="selectyg"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              v-if="selectwx == '2' ? true : false"
                              multiple
                              label="text"
                              :options="ryoptions"
                            />
                          </div>
                        </b-form-group>
                      </div>
                    </b-form>
                  </b-modal>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px">
                  <label label-for="nickname" style="padding-top: 12px"
                    >选择群发类型：</label
                  >
                </div>
                <div style="float: left; padding-top: 12px">
                  <b-form-radio
                    v-model="grouptype"
                    name="grouptype"
                    value="0"
                    @change="ckgroup"
                  >
                    全部
                  </b-form-radio>
                </div>
                <div style="float: left; margin-left: 10px; padding-top: 12px">
                  <b-form-radio
                    v-model="grouptype"
                    name="grouptype"
                    value="1"
                    @change="ckgroup"
                  >
                    好友
                  </b-form-radio>
                </div>
                <div style="float: left; margin-left: 10px; padding-top: 6px">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    :disabled="grouptype == '1' ? false : true"
                    v-b-modal.modal-friendtag
                  >
                    选择好友标签
                  </b-button>
                  <b-modal
                    id="modal-friendtag"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="选择人群标签"
                    @ok="ok"
                  >
                    <b-form>
                      <b-form-group>
                        <div style="float: left; width: 450px">
                          <v-select
                            v-model="tag"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="text"
                            v-if="selectwx == '1' ? true : false"
                            :options="tagoption"
                          />
                        </div>
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </div>
                <!-- <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="grouptype" name="grouptype" value="1">
                    人群标签
                  </b-form-radio>
                </div>
                <div
                  style="
                    float: left;
                    margin-left: 10px;
                    margin-left: 10px;
                    width: 350px;
                  "
                >
                  <v-select
                    v-model="tag"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="text"
                    :options="tagoption"
                    :disabled="grouptype == '1' ? false : true"
                  />
                </div> -->
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="grouptype" name="grouptype" value="2">
                    群组标签
                  </b-form-radio>
                </div>
                <div style="float: left; margin-left: 10px; padding-top: 6px">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    :disabled="grouptype == '2' ? false : true"
                    v-b-modal.modal-grouptag
                  >
                    选择群组标签
                  </b-button>
                  <b-modal
                    id="modal-grouptag"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="选择群组标签"
                    @ok="ok"
                  >
                    <b-form>
                      <b-form-group>
                        <div style="float: left">
                          <b-form-radio
                            v-model="selectwx"
                            name="selectwx"
                            value="1"
                            @input="clear()"
                          >
                            按归属部门筛选
                          </b-form-radio>
                        </div>
                        <div style="float: left; width: 450px">
                          <v-select
                            v-model="selectbm"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="text"
                            v-if="selectwx == '1' ? true : false"
                            :options="bmoptions"
                          />
                        </div>
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Full Name -->
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px; margin-top: 10px">
                  <label label-for="nickname">添加方式：</label>
                </div>
                <div style="float: left; padding-top: 12px">
                  <b-form-radio v-model="addtype" name="addtype" value="3">
                    全部
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="addtype" name="addtype" value="1">
                    主动
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="addtype" name="addtype" value="2">
                    被动
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="addtype" name="addtype" value="0">
                    未知
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px; margin-top: 10px">
                  <label label-for="nickname">性别：</label>
                </div>
                <div style="float: left; padding-top: 12px">
                  <b-form-radio v-model="sex" name="sex" value="3">
                    全部
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="sex" name="sex" value="1">
                    男
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="sex" name="sex" value="2">
                    女
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="sex" name="sex" value="0">
                    未知
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px; margin-top: 15px">
                  <label label-for="nickname">距添加时间：</label>
                </div>
                <div style="float: left; padding-top: 12px">
                  <b-form-radio v-model="addtime" name="addtime" value="">
                    不选
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="addtime" name="addtime" value="1">
                    1日内
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="addtime" name="addtime" value="7">
                    7日内
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="addtime" name="addtime" value="30">
                    30日内
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="addtime" name="addtime" value="0">
                    自定义
                  </b-form-radio>
                </div>
                <div
                  style="
                    float: left;
                    width: 60px;
                    padding-top: 5px;
                    margin-left: 10px;
                  "
                >
                  <b-form-input
                    id="input-sm"
                    size="sm"
                    v-model="setaddtimemin"
                    placeholder="最小值"
                  />
                </div>
                <div
                  style="
                    float: left;
                    width: 10px;
                    padding-top: 10px;
                    margin-left: 10px;
                  "
                >
                  ~
                </div>
                <div
                  style="
                    float: left;
                    width: 60px;
                    padding-top: 5px;
                    margin-left: 10px;
                  "
                >
                  <b-form-input
                    id="input-sm"
                    size="sm"
                    v-model="setaddtimemax"
                    placeholder="最大值"
                  />
                </div>

                <div
                  style="
                    float: left;

                    padding-top: 10px;
                    margin-left: 10px;
                  "
                >
                  （单位：日）
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px; margin-top: 15px">
                  <label label-for="nickname">距最后会话时间：</label>
                </div>
                <div style="float: left; padding-top: 12px">
                  <b-form-radio v-model="interval" name="interval" value="">
                    不选
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="interval" name="interval" value="1">
                    1日内
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="interval" name="interval" value="7">
                    7日内
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="interval" name="interval" value="30">
                    30日内
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="interval" name="interval" value="0">
                    自定义
                  </b-form-radio>
                </div>

                <div
                  style="
                    float: left;
                    width: 60px;
                    padding-top: 5px;
                    margin-left: 10px;
                  "
                >
                  <b-form-input
                    id="input-sm"
                    v-model="intervaltimemin"
                    size="sm"
                    placeholder="最小值"
                  />
                </div>
                <div
                  style="
                    float: left;
                    width: 10px;
                    padding-top: 10px;
                    margin-left: 10px;
                  "
                >
                  ~
                </div>
                <div
                  style="
                    float: left;
                    width: 60px;
                    padding-top: 5px;
                    margin-left: 10px;
                  "
                >
                  <b-form-input
                    id="input-sm"
                    v-model="intervaltimemax"
                    size="sm"
                    placeholder="最大值"
                  />
                </div>

                <div
                  style="
                    float: left;

                    padding-top: 13px;
                    margin-left: 10px;
                  "
                >
                  （单位：日）
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px; margin-top: 15px">
                  <label label-for="nickname">是否有购物行为</label>
                </div>
                <div style="float: left; padding-top: 12px">
                  <b-form-radio v-model="shopping" name="shopping" value="0">
                    无
                  </b-form-radio>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-form-radio v-model="shopping" name="shopping" value="1">
                    有
                  </b-form-radio>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px; margin-top: 15px">
                  <label label-for="nickname">群发内容：</label>
                </div>
                <div style="float: left; padding-top: 12px">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    v-b-modal.modal-text
                  >
                    添加文字
                  </b-button>
                  <b-modal
                    id="modal-text"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="添加文字"
                    @ok="addrestype(1)"
                  >
                    <b-form>
                      <b-form-group>
                        <div>
                          <b-button
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            variant="outline-primary"
                            pill
                            size="sm"
                            @click="inputname"
                          >
                            姓名
                          </b-button>
                          <b-form-textarea
                            id="textarea-default"
                            placeholder="输入发送内容"
                            v-model="content"
                            rows="3"
                          />
                        </div>
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    @click="showModal('modal-pic')"
                  >
                    选择图片
                  </b-button>
                  <b-modal
                    ref="modal-pic"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="选择图片"
                    @ok="addrestype(2)"
                  >
                    <b-form>
                      <b-form-group>
                        <b-row>
                          <!-- latest photo loop -->
                          <b-col
                            v-for="data in piclist"
                            :key="data.picurl"
                            style="width: 200px"
                          >
                            <b-link @click="check(data)">
                              <b-img
                                fluid
                                rounded
                                :src="data.picurl"
                                :alt="data.name"
                                class="container"
                              />
                              <b-form-radio
                                :id="'ck' + data.id"
                                name="some-radios"
                                class="checkbox"
                                @change="changinfo(data)"
                              >
                              </b-form-radio>
                            </b-link>
                          </b-col>
                          <!-- latest photo loop -->
                        </b-row>
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    v-b-modal.modal-sound
                  >
                    选择语音
                  </b-button>
                  <b-modal
                    id="modal-sound"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="选择群组标签"
                    @ok="addrestype(3)"
                  >
                    <b-form>
                      <b-form-group>
                        <div style="float: left">
                          <b-form-radio
                            v-model="selectwx"
                            name="selectwx"
                            value="1"
                            @input="clear()"
                          >
                            按归属部门筛选
                          </b-form-radio>
                        </div>
                        <div style="float: left; width: 450px">
                          <v-select
                            v-model="selectbm"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="text"
                            v-if="selectwx == '1' ? true : false"
                            :options="bmoptions"
                          />
                        </div>
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </div>

                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    v-b-modal.modal-video
                  >
                    选择视频
                  </b-button>

                  <b-modal
                    id="modal-video"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="选择群组标签"
                    @ok="addrestype(4)"
                  >
                    <b-form>
                      <b-form-group>
                        <div style="float: left">
                          <b-form-radio
                            v-model="selectwx"
                            name="selectwx"
                            value="1"
                            @input="clear()"
                          >
                            按归属部门筛选
                          </b-form-radio>
                        </div>
                        <div style="float: left; width: 450px">
                          <v-select
                            v-model="selectbm"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="text"
                            v-if="selectwx == '1' ? true : false"
                            :options="bmoptions"
                          />
                        </div>
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    v-b-modal.modal-shipinghao
                  >
                    选择视频号
                  </b-button>
                  <b-modal
                    id="modal-shipinghao"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="选择群组标签"
                    @ok="ok"
                  >
                    <b-form>
                      <b-form-group>
                        <div style="float: left">
                          <b-form-radio
                            v-model="selectwx"
                            name="selectwx"
                            value="1"
                            @input="clear()"
                          >
                            按归属部门筛选
                          </b-form-radio>
                        </div>
                        <div style="float: left; width: 450px">
                          <v-select
                            v-model="selectbm"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="text"
                            v-if="selectwx == '1' ? true : false"
                            :options="bmoptions"
                          />
                        </div>
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </div>

                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    v-b-modal.modal-link
                  >
                    选择链接
                  </b-button>
                  <b-modal
                    id="modal-link"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="选择群组标签"
                    @ok="addrestype(6)"
                  >
                    <b-form>
                      <b-form-group>
                        <div style="float: left">
                          <b-form-radio
                            v-model="selectwx"
                            name="selectwx"
                            value="1"
                            @input="clear()"
                          >
                            按归属部门筛选
                          </b-form-radio>
                        </div>
                        <div style="float: left; width: 450px">
                          <v-select
                            v-model="selectbm"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="text"
                            v-if="selectwx == '1' ? true : false"
                            :options="bmoptions"
                          />
                        </div>
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </div>
                <div style="float: left; padding-top: 12px; margin-left: 10px">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    size="sm"
                    variant="primary"
                    v-b-modal.modal-weapp
                  >
                    选择小程序
                  </b-button>
                  <b-modal
                    id="modal-weapp"
                    cancel-variant="outline-secondary"
                    ok-title="添加"
                    cancel-title="关闭"
                    centered
                    no-close-on-backdrop
                    title="选择群组标签"
                    @ok="addrestype(13)"
                  >
                    <b-form>
                      <b-form-group>
                        <label for="AppId">AppId:</label>
                        <b-form-input
                          id="AppId"
                          placeholder="小程序AppId"
                          v-model="AppId"
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="Title">标题:</label>
                        <b-form-input
                          id="Title"
                          placeholder="标题"
                          v-model="Title"
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="Des">描述:</label>
                        <b-form-input
                          id="Des"
                          placeholder="设备串号"
                          v-model="Des"
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="deviceid">缩略图:</label>
                        <b-form-input
                          id="Thumb"
                          placeholder="缩略图"
                          v-model="Thumb"
                        />
                      </b-form-group>
                      <b-form-group>
                        <label for="PagePath">小程序路径:</label>
                        <b-form-input
                          id="PagePath"
                          placeholder="设备串号"
                          v-model="PagePath"
                        />
                      </b-form-group>
                    </b-form>
                  </b-modal>
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px; margin-top: 15px">
                  <label label-for="nickname">首次推送时间</label>
                </div>
                <div style="float: left; padding-top: 12px">
                  <flat-pickr
                    v-model="sendtime"
                    class="form-control"
                    :config="config"
                  />
                </div>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- Field: Email -->
            <b-col cols="12">
              <b-form-group>
                <div style="float: left; width: 120px; margin-top: 25px">
                  <label label-for="nickname">推送频率：</label>
                </div>
                <div style="float: left; padding-top: 12px">
                  <b-form-radio v-model="looptype" name="looptype" value="1">
                    仅推送一次
                  </b-form-radio>
                  <br />
                  <div style="padding-top: 20px; float: left">
                    <b-form-radio v-model="looptype" name="looptype" value="2">
                      定时循环推送
                    </b-form-radio>
                  </div>
                  <div
                    style="float: left; padding-left: 30px"
                    v-if="looptype == 2"
                  >
                    <div style="float: left; padding-top: 20px; width: 60px">
                      推送间隔
                    </div>
                    <div style="float: left; padding-top: 10px">
                      <b-form-input
                        id="day"
                        v-model="day"
                        size="sm"
                        style="width: 30px; margin-right: 5px"
                      />
                    </div>
                    <div
                      style="
                        padding-top: 20px;
                        float: left;
                        padding-right: 10px;
                      "
                    >
                      天
                    </div>
                    <div style="float: left; padding-top: 10px">
                      <b-form-input
                        id="hour"
                        size="sm"
                        v-model="hour"
                        style="width: 30px; margin-right: 5px"
                      />
                    </div>
                    <div
                      style="
                        padding-top: 20px;
                        float: left;
                        padding-right: 10px;
                      "
                    >
                      时
                    </div>
                    <div style="float: left; padding-top: 10px">
                      <b-form-input
                        id="min"
                        size="sm"
                        v-model="min"
                        style="width: 30px; margin-right: 5px"
                      />
                    </div>
                    <div
                      style="
                        padding-top: 20px;
                        float: left;
                        padding-right: 10px;
                      "
                    >
                      分
                    </div>
                    <div
                      style="
                        padding-top: 20px;
                        float: left;
                        padding-right: 10px;
                      "
                    >
                      推送次数
                    </div>
                    <div style="float: left; padding-top: 10px">
                      <b-form-input
                        id="Title"
                        size="sm"
                        v-model="num"
                        style="width: 30px; margin-right: 10px"
                      />
                    </div>
                    <div
                      style="
                        padding-top: 20px;
                        float: left;
                        padding-right: 10px;
                        color: red;
                      "
                    >
                      *未填或填0表示定时无限循环*
                    </div>
                  </div>
                </div>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="save"
          >
            保 存
          </b-button>

          <b-button
            variant="outline-secondary"
            type="reset"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          >
            重 置
          </b-button>
          <!-- Field: Full Name -->
        </b-form>
        <!-- Action Buttons -->
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import {
  BButton,
  BMedia,
  BRow,
  BCol,
  BLink,
  BImg,
  BFormGroup,
  BFormInput,
  BForm,
  BFormTextarea,
  BBadge,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BInputGroup,
  BFormFile,
  BFormCheckbox,
  BFormRadio,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { Mandarin } from "flatpickr/dist/l10n/zh.js";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import useJwt from "@/auth/jwt/useJwt";
import BCardCode from "@core/components/b-card-code";
export default {
  components: {
    BButton,
    BMedia,
    BCardCode,
    BLink,
    BImg,
    BFormTextarea,
    BRow,
    BBadge,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormFile,
    BFormCheckbox,
    vSelect,
    BFormRadio,
    flatPickr,
    ToastificationContent,
  },

  directives: {
    Ripple,
  },
  data() {
    return {
      config: {
        enableTime: false,
        allowInput: true,
        defaultDate: this.ToTime(),
        dateFormat: "Y-m-d H:i",
        locale: Mandarin,
      },
      cid: "",
      aid: "",
      title: "",
      account: "",
      grouptype: "0",
      addtype: "3",
      sex: "3",
      addtime: "",
      interval: "",
      restype: "",
      piclist: [],
      content: "",
      selectname: "",
      setaddtimemin: "",
      setaddtimemax: "",
      intervaltimemin: "",
      intervaltimemax: "",
      sendtime: null,
      looptype: "1",
      looptime: "",
      day: "",
      hour: "",
      min: "",
      num: "",
      AppId: "",
      tag: "",
      shopping: 0,
      pageLength: 20,
      selectwx: "1",
      Title: "",
      Thumb: "",
      PagePath: "",
      Des: "",
      picurl: "",
      bmoptions: [],
      ryoptions: [],
      selectbm: [],
      selectyg: [],
      tagoption: [],
    };
  },
  methods: {
    inputname() {
      console.log("确定")
      this.content = "[姓名]" + this.content;
    },
    addrestype(num) {
      console.log("类型"+this.content)
      this.restype = num;
    },
    showModal(ss) {
      this.$refs[ss].show();
      this.sucailist("2");
    },
    check(data) {
      document.getElementById("ck" + data.id).checked = true;
      this.content = data.picurl;
    },
    sucailist(type) {
      useJwt
        .picpageList({
          cid: this.cid,
          type: type,
          page: 1,
          rows: this.pageLength,
        })
        .then((res) => {
          console.log("素材..", JSON.stringify(res));
          this.piclist = res.data.data.rows;
        });
    },
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;
      const D = `${
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      } `;
      const h = `${
        date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
      }:`;
      const m = `${
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
      }:`;
      const s =
        date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      return Y + M + D + h + m + s;
    },
    ckgroup() {
      if (this.grouptype == "1") {
      }
    },
    ToTime() {
      const date = new Date(); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;
      const D = `${
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      } `;
      const h = `${
        date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
      }:`;
      const m = `${
        date.getMinutes() + 5 > 9
          ? date.getMinutes() + 5
          : "0" + date.getMinutes() + 5
      }`;

      return Y + M + D + h + m;
    },
    ok() {
      this.selectname = this.selectbm.text;
      // if (this.selectwx == "1") {
      //   this.selectbm.forEach((item, i) => {
      //     this.selectname.push(item.text);
      //   });
      // } else {
      //   this.selectyg.forEach((item, i) => {
      //     this.selectname.push(item.text);
      //   });
      // }
    },
    clear() {
      this.selectbm = [];
      this.selectyg = [];
    },
    showToast(variant, position, text) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "错误",
            icon: "InfoIcon",
            text: text,
            variant,
          },
        },
        {
          position,
        }
      );
    },
    save() {
      console.log("任务名称", this.title);
      if (this.title == "") {
        this.showToast("danger", "top-center", "请填写标题");
        return;
      }
      if (this.selectwx == "1") {
        console.log("选择的部门", this.selectbm.value);
        if (typeof this.selectbm.value == "undefined") {
          this.showToast("danger", "top-center", "请选择执行帐号");
          return;
        }
      } else {
        console.log("选择的员工", this.selectyg.value);
      }
      if (this.addtime == "0") {
        if (this.setaddtimemin == "") {
          this.showToast("danger", "top-center", "请填写距添加时间最小值");
          return;
        }
        if (this.setaddtimemax == "") {
          this.showToast("danger", "top-center", "请填写距添加时间最大值");
          return;
        }
      }
      if (this.interval == "0") {
        if (this.intervaltimemin == "") {
          this.showToast("danger", "top-center", "请填写最后会话时间最小值");
          return;
        }
        if (this.intervaltimemax == "") {
          this.showToast("danger", "top-center", "请填写最后会话时间最大值");
          return;
        }
      }
      console.log("群发类型", this.grouptype);
      console.log("添加方式", this.addtype);
      console.log("性别", this.sex);
      if (this.grouptype != "0") {
        var tagg = "";
        this.tag.forEach((item) => {
          tagg = tagg + item.value + ",";
        });
      }

      console.log("标签", tagg);
      console.log("添加时间", this.addtime);
      if (this.addtime != "") {
        this.setaddtimemin = "0";
        this.setaddtimemax = this.addtime + "";
      }
      console.log("最后会话时间", this.interval);
      if (this.interval != "") {
        this.intervaltimemin = "0";
        this.intervaltimemax = this.interval + "";
      }
      console.log("最后回话", this.intervaltimemin);
      console.log("最后回话", this.intervaltimemax);
      console.log("首次推送时间", this.sendtime);
      console.log("是否购物", this.shopping);
      console.log("循环次数", this.looptype);

      if (this.content == "") {
        this.showToast("danger", "top-center", "请填写发送内容");
        return;
      }
      if (this.sendtime != "") {
        let date1 = new Date(Date.parse(this.sendtime));
        let yy = new Date().getFullYear();
        let mm = new Date().getMonth() + 1;
        let dd = new Date().getDate();
        let hh = new Date().getHours();
        let mf =
          new Date().getMinutes() < 10
            ? "0" + new Date().getMinutes()
            : new Date().getMinutes();
        let ss =
          new Date().getSeconds() < 10
            ? "0" + new Date().getSeconds()
            : new Date().getSeconds();
        let gettime = yy + "/" + mm + "/" + dd + " " + hh + ":" + mf + ":";
        let date2 = new Date(Date.parse(gettime));
        if (date1 < date2) {
          console.log("data1", date1);
          console.log("data2", date2);
          this.showToast("danger", "top-center", "发送时间小于当前时间");
          return;
        } else {
          console.log("data111", date1);
          console.log("data2222", date2);
        }
      }
      useJwt
        .AiSendInsert({
          accountid: this.aid,
          interval: this.interval,
          cid: this.cid,
          addtime: this.addtime,
          grouptype: this.grouptype,
          did: this.selectbm.value,
          yg: this.selectyg.value,
          tasktype: "1",
          tag: tagg,
          title: this.title,
          execute_time: this.sendtime,
          restype: this.restype,
          content: this.content,
          title: this.title,
          setaddtimemin: this.setaddtimemin,
          setaddtimemax: this.setaddtimemax,
          intervaltimemin: this.intervaltimemin,
          intervaltimemax: this.intervaltimemax,
          num: this.num,
          addtype: this.addtype,
          shopping: this.shopping,
          gender: this.sex,
          day: this.day,
          hour: this.hour,
          min: this.min,
          num: this.num,
          interval: this.interval,
        })
        .then((res) => {
          console.log("res", res);
        });
    },
  },
  mounted() {
    this.sendtime = this.config.defaultDate;
    const user = JSON.parse(localStorage.getItem("userData"));
    console.log("user", user);
    this.cid = user.cid;
    this.aid = user.id;
    useJwt.queryByCid({ cid: this.cid }).then((response) => {
      if (response.data.code == 0) {
        const forArr = response.data.data;
        forArr.forEach((item, i) => {
          this.bmoptions.push({ text: item.name, value: item.id });
        });
      }
    });

    useJwt.queryAccountsbyCid({ cid: this.cid }).then((response) => {
      const forArr = response.data.data;
      forArr.forEach((item, i) => {
        this.ryoptions.push({ text: item.nickname, value: item.id });
      });
    });
    useJwt.queryTagByCidType({ cid: this.cid, type: 1 }).then((res) => {
      const list = res.data.data;
      console.log("list", list);
      list.forEach((item, i) => {
        this.tagoption.push({ text: item.name, value: item.id });
      });
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style>
.container {
  position: relative;
  width: 100px;
  height: 100px;
  float: left;
  margin-left: 10px;
}

.checkbox {
  position: absolute;
  bottom: 0px;
  right: 0px;
  left: 120px;
}
</style>